import { initializeApp } from 'firebase/app';
import { getDatabase, connectDatabaseEmulator } from 'firebase/database';
import { getAnalytics } from "firebase/analytics";
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyBnkPpWrLWD78Za76fJqDyebP3Y4yTyagc",
  authDomain: "boasoft.firebaseapp.com",
  databaseURL: "https://boasoft-default-rtdb.firebaseio.com",
  projectId: "boasoft",
  storageBucket: "boasoft.appspot.com",
  messagingSenderId: "160055909984",
  appId: "1:160055909984:web:31991aa8490f128fbeed05",
  measurementId: "G-C79HQB2BQ3"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const database = getDatabase(app);
const functions = getFunctions(app);
const firestore = getFirestore(app)

export { database };

if (process.env.NODE_ENV === 'development') {
  connectDatabaseEmulator(database, 'localhost', 9000);
  connectFunctionsEmulator(functions, 'localhost', 5001);
  connectFirestoreEmulator(firestore, 'localhost', 5002)
}
